import React from 'react';
import { createRoot } from 'react-dom/client';

import PageContainer from './containers/PageContainer';

const rootNode = document.getElementById('user-auth-root');
const links = JSON.parse(rootNode.dataset.links);
const formError = JSON.parse(rootNode.dataset.formError);

const UserAuth = () => (
  <PageContainer links={links} formError={formError} />
);

document.addEventListener('DOMContentLoaded', () => {
  const root = createRoot(rootNode);
  root.render(<UserAuth />);
});
