import React from 'react';
import T from 'prop-types';
import Button from '../../../../components/Buttons/Button';

const SuccessForgotStep = ({
  email,
  onLoginClick,
}) => (
  <div className="user-auth-message">
    <div className="user-auth-message-heading">
      Success!
    </div>
    <div className="user-auth-message-text">
      A password reset email was sent to <br />
      {email}. Please check your spam folders.
      If you need further assistance please email <a href="mailto:success@apptopia.com">success@apptopia.com</a>.
    </div>
    <div className="user-auth-message-button">
      <Button onClick={onLoginClick} className="hms-btn hms-btn--lg hms-btn--primary">
        Sign In
      </Button>
    </div>
  </div>
);

SuccessForgotStep.propTypes = {
  onLoginClick: T.func.isRequired,
  email: T.string.isRequired,
};

export default SuccessForgotStep;
