import React, { useState } from 'react';
import T from 'prop-types';
import { compact, values, isEmpty } from 'lodash';

import Button from '../../../../components/Buttons/Button';
import InputText from '../../../../components/Inputs/InputText';
import helpers from '../../../../utils/helpers';


const ForgotPassword = ({
  email,
  onLoginClick,
  onForgotFormSubmit,
  responseError,
}) => {
  const [error, setError] = useState({});
  const [emailWithDomain, setEmailWithDomain] = useState(email);

  const isFormValid = compact(values(error)).length === 0
    && emailWithDomain.length > 0;

  const setters = {
    setError,
    setEmailWithDomain,
  };

  const handleUserChange = (v, e) => {
    helpers.updateInputValue(e, setters);
  };

  const handleSubmit = (e) => {
    if (!isFormValid) return;
    e.preventDefault();
    onForgotFormSubmit(emailWithDomain);
  };

  const emailError = responseError.email;

  return (
    <div className="user-auth-forgot">
      <h2 className="user-auth-forgot-heading">Reset Your Password</h2>
      <form onSubmit={handleSubmit}>
        <fieldset className="form-group">
          <InputText
            value={emailWithDomain}
            onChange={handleUserChange}
            name="emailWithDomain"
            type="email"
            placeholder="Email"
            autoComplete="email"
          />
          {emailError && <p className="text-12 text-rose text-center mt-sm">{emailError}</p>}
        </fieldset>
        <Button
          size="lg"
          className="submit-step-btn"
          disabled={isEmpty(emailWithDomain) || !isEmpty(error.emailWithDomain)}
          onClick={handleSubmit}
        >
          Send Reset Email
        </Button>
      </form>
      <div className="user-auth-back">
        <button onClick={onLoginClick} className="btn-link">Back to Sign In</button>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  email: T.string,
  onLoginClick: T.func,
  onForgotFormSubmit: T.func,
  responseError: T.object,
};

export default ForgotPassword;
