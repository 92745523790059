import React from 'react';
import T from 'prop-types';
import { demoPageUrl } from '../../../alchemy/alchemy-static-page-urls';

const UserSocials = ({
  links,
  onForgotClick,
  showEmail,
}) => (
  <div className="user-auth-socials">
    <div className="user-auth-socials-forgot">
      {showEmail
        ? <a className="request-demo-link" href={demoPageUrl}>Don’t have an Apptopia account?&nbsp;<span className="text-underline">Request a Demo</span></a>
        : <button className="btn-link" onClick={onForgotClick}>Forgot Password?</button>
      }
    </div>
    <div className="user-auth-socials-list">
      <a
        href={links.google}
        className="hms-btn hms-btn--lg hms-btn--secondary"
        data-ga-category="Login"
        data-method="post"
      >
        <span className="social-img google-img" />
        Sign in with Google
      </a>
      <a
        href={links.linkedin}
        className="hms-btn hms-btn--lg hms-btn--secondary"
        data-ga-category="Login"
        data-method="post"
      >
        <span className="social-img linkedin-img" />
        Sign in with LinkedIn
      </a>
    </div>
  </div>
);

UserSocials.propTypes = {
  links: T.object.isRequired,
  onForgotClick: T.func.isRequired,
  showEmail: T.bool,
};

export default UserSocials;
