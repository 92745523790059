import React from 'react';
import T from 'prop-types';
import Button from '../../../../components/Buttons/Button';

const SuccessStep = ({
  onLoginClick,
}) => (
  <div className="user-auth-message">
    <div className="user-auth-message-heading">
      Success!
    </div>
    <div className="user-auth-message-text">
      An email will be sent from <a href="mailto:success@apptopia.com">success@apptopia.com</a>.
      Please check your spam folders. If you need additional assistance please email <a href="mailto:success@apptopia.com">success@apptopia.com</a>.
    </div>
    <div className="user-auth-message-button">
      <Button onClick={onLoginClick} className="hms-btn hms-btn--lg hms-btn--primary">
        Sign In
      </Button>
    </div>
  </div>
);

SuccessStep.propTypes = {
  onLoginClick: T.func.isRequired,
};

export default SuccessStep;
