import React, { useState, useCallback } from 'react';
import T from 'prop-types';

import { isEmpty } from 'lodash';
import UserAuthForm from '../components/UserAuthForm';
import ConfirmationStep from '../components/ConfirmationStep';
import SuccessStep from '../components/SuccessStep';
import ForgotPassword from '../components/ForgotPassword';
import SuccessForgotStep from '../components/SuccessForgotStep';

import { login, forgotPassword, resendConfirmation } from '../api';

const PageContainer = ({
  links,
  formError,
}) => {
  const [step, setStep] = useState('userForm');
  const [processing, setProcessing] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [responseError, setResponseError] = useState({});

  const setConfirmStep = useCallback((email) => {
    setUserEmail(email);
    setStep('confirmStep');
  }, []);

  const setSuccessStep = useCallback(() => {
    setStep('successStep');
  }, []);

  const setForgotPassword = useCallback((email) => {
    setResponseError({});
    setUserEmail(email);
    setStep('forgotPassword');
  }, []);

  const handleError = (err) => {
    const emailError = err.response.data.errors.email;
    const errorMessage = err.response.data.errors.message;
    if (emailError) {
      setResponseError({ email: `Email ${emailError[0]}` });
    } else if (errorMessage) {
      setResponseError({ email: errorMessage });
    } else {
      setResponseError({ email: 'API error' });
    }
  };

  const handleUserFormSubmit = (userData) => {
    login({ user: userData }).then(({ data }) => {
      window.location.href = data.location;
    }).catch((error) => {
      setResponseError(error.response.data.errors);
    });
  };

  const handleResendClick = () => {
    resendConfirmation({ user: { email: userEmail } }).then(() => {
      setSuccessStep();
    }).catch((error) => {
      setResponseError(error.response.data.errors);
    });
  };

  const handleLoginClick = () => setStep('userForm');

  const handleForgotPasswordClick = (email) => {
    setResponseError({});
    setUserEmail(email);
    forgotPassword({ user: { email } }).then(() => {
      setStep('successForgotStep');
    }).catch(error => handleError(error));
  };

  return (
    <div className="user-auth-page">
      <header className="user-auth-page__header">
        <a
          className="logo flex-no-shrink"
          data-label="Apptopia Logo"
          href="https://apptopia.com/"
        >
          <span className="hidden">Apptopia Logo</span>
        </a>
      </header>
      <div className="user-auth-container">
        {!isEmpty(formError) && (
          <div className="user-auth-form-error">{formError}</div>
        )}
        {step === 'userForm' && (
          <UserAuthForm
            links={links}
            processing={processing}
            setProcessing={setProcessing}
            onUserFormSubmit={handleUserFormSubmit}
            responseError={responseError}
            onForgotClick={setForgotPassword}
            onConfirmationRequred={setConfirmStep}
            email={userEmail}
          />
        )}
        {step === 'forgotPassword' && (
          <ForgotPassword
            email={userEmail}
            onLoginClick={handleLoginClick}
            onForgotFormSubmit={handleForgotPasswordClick}
            responseError={responseError}
          />
        )}
        {step === 'confirmStep' && (
          <ConfirmationStep
            onResendClick={handleResendClick}
          />
        )}
        {step === 'successStep' && (
          <SuccessStep
            onLoginClick={handleLoginClick}
          />
        )}
        {step === 'successForgotStep' && (
          <SuccessForgotStep
            email={userEmail}
            onLoginClick={handleLoginClick}
          />
        )}
      </div>
      <footer className="user-auth-page__footer">
        Copyright © {(new Date()).getFullYear()} Apptopia Inc. All rights reserved.
      </footer>
    </div>
  );
};

PageContainer.propTypes = {
  links: T.object,
  formError: T.array,
};

export default PageContainer;
