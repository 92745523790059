import axios from '../../../../configs/axios';

export const finish = data => axios.patch('/confirm.json', data);

export const authOptions = email => axios.get('/auth/options.json', { params: { email } });

export const login = data => axios.post('/users/sign_in.json', data, { headers: { 'X-Requested-With': 'XMLHttpRequest' } });

export const forgotPassword = data => axios.post('/users/password.json', data);

export const resendConfirmation = data => axios.post('/users/confirmation.json', data);
