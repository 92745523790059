import React from 'react';
import T from 'prop-types';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import Button from '../../../../components/Buttons/Button';
import InputText from '../../../../components/Inputs/InputText';

const UserEmailForm = ({
  emailWithDomain,
  showEmail,
  error,
  processing,
  handleUserChange,
  handleEmailSubmit,
}) => (
  <form onSubmit={handleEmailSubmit}>
    <fieldset className="form-group">
      <InputText
        value={emailWithDomain}
        onChange={handleUserChange}
        autoComplete="email"
        name="emailWithDomain"
        type="email"
        placeholder="Email"
      />
    </fieldset>
    <Button
      size="lg"
      className={cx('submit-step-btn', showEmail && 'hide')}
      loading={processing}
      disabled={isEmpty(emailWithDomain) || !isEmpty(error.emailWithDomain)}
      onClick={handleEmailSubmit}
    >
      Continue
    </Button>
  </form>
);

UserEmailForm.propTypes = {
  emailWithDomain: T.string,
  showEmail: T.bool,
  processing: T.bool.isRequired,
  handleUserChange: T.func,
  handleEmailSubmit: T.func,
  error: T.object,
};

export default UserEmailForm;
