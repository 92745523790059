import React, { forwardRef } from 'react';
import T from 'prop-types';
import cx from 'classnames';
import { toString } from 'lodash';

const emptyCb = () => {};

const renderIcon = icon => (
  <span className="hms-input-text-icon">
    {typeof icon === 'string' ? <i className={icon} /> : icon}
  </span>
);

// eslint-disable-next-line prefer-arrow-callback
const InputText = forwardRef(function InputText({
  value,
  name,
  type = 'text',
  size,
  placeholder,
  disabled,
  isInvalid,
  autoComplete = 'off',
  className,
  iconLeft,
  iconRight,
  onChange = emptyCb,
  ...restProps
}, ref) {
  const handleChange = (event) => {
    onChange(event.target.value, event);
  };

  const inputValue = typeof value === 'string' ? value : toString(value);

  return (
    <div className={cx('hms-input-text-container', className, {
      'is-disabled': disabled,
      'with-left-icon': iconLeft,
      'with-right-icon': iconRight,
    })}
    >
      {iconLeft && renderIcon(iconLeft)}
      <input
        ref={ref}
        value={inputValue}
        type={type}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
        className={cx('hms-input-text', {
          'hms-input-text--invalid': isInvalid,
          [`hms-input-text--${size}`]: size,
        })}
        {...restProps}
        onChange={handleChange}
      />
      {iconRight && renderIcon(iconRight)}
    </div>
  );
});

InputText.propTypes = {
  value: T.oneOfType([T.string, T.number]).isRequired,
  name: T.string,
  type: T.oneOf(['text', 'password', 'email', 'number']),
  size: T.oneOf(['sm', 'lg']),
  placeholder: T.string,
  disabled: T.bool,
  isInvalid: T.bool,
  autoComplete: T.string,
  className: T.string,
  iconLeft: T.oneOfType([T.string, T.node]),
  iconRight: T.oneOfType([T.string, T.node]),
  onChange: T.func.isRequired,
};

export default InputText;

// Global component designed according to design system
