import React, { useState, useRef, useEffect } from 'react';
import T from 'prop-types';
import cx from 'classnames';

import Button from '../../../../components/Buttons/Button';
import Tooltip from '../../../../components/Tooltip';
import InputText from '../../../../components/Inputs/InputText';

const UserPasswordForm = ({
  password,
  handleSubmit,
  processing,
  isFormValid,
  handlePasswordChange,
  error,
  showEmail,
}) => {
  const initInputTypes = { password: 'password' };
  const passwordRef = useRef(null);

  const [inputTypes, setInputTypes] = useState(initInputTypes);

  const handleShowPassword = (option) => {
    setInputTypes(prevType => ({
      ...prevType,
      [option]: prevType[option] === 'text' ? 'password' : 'text',
    }));
  };

  useEffect(() => {
    if (!showEmail) {
      setTimeout(() => passwordRef.current.focus(), 150);
    }
  }, [showEmail]);

  const icon = option => (
    <Tooltip
      content="Show/Hide Password"
      placement="right"
    >
      <i
        className={cx('text-black',
        inputTypes[option] === 'password' ? 'hicon-eye-open' : 'hicon-eye-hide')}
        onClick={() => handleShowPassword(option)}
      />
    </Tooltip>
  );

  return (
    <form onSubmit={handleSubmit} className={cx('user-auth-password-form', !showEmail && 'fade-in')}>
      <fieldset className="form-group ">
        <InputText
          autoComplete="new-password"
          onChange={handlePasswordChange}
          value={password}
          type={inputTypes.password}
          name="password"
          id="password"
          pattern=".{8,}"
          iconRight={icon('password')}
          placeholder="Password"
          ref={passwordRef}
        />
      </fieldset>

      <Button
        size="lg"
        className="submit-step-btn"
        loading={processing}
        disabled={!isFormValid}
        onClick={handleSubmit}
        type="submit"
      >
        Sign In
      </Button>

      {error && error.email && <p className="text-12 text-rose text-center mt-sm">{error.email}</p>}
    </form>
  );
};

UserPasswordForm.propTypes = {
  processing: T.bool.isRequired,
  password: T.string,
  error: T.object,
  handlePasswordChange: T.func,
  handleSubmit: T.func,
  isFormValid: T.bool,
  showEmail: T.bool,
};

export default UserPasswordForm;
