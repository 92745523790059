import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { values, compact, isEmpty } from 'lodash';

import helpers from '../../../../utils/helpers';
import { authOptions } from '../api';
import UserEmailForm from './UserEmailForm';
import UserPasswordForm from './UserPasswordForm';
import UserSocials from './UserSocials';
import Auth0Form from './Auth0Form';

const auth0FormId = 'auth0Form';

const submitAuth0Form = (connection) => {
  if (connection) {
    document.forms[auth0FormId].action += `?connection=${connection}`;
  }
  document.forms[auth0FormId].submit();
};

const UserAuthFormStep = ({
  links,
  processing,
  setProcessing,
  onUserFormSubmit,
  responseError,
  onForgotClick,
  onConfirmationRequred,
  email,
}) => {
  const [error, setError] = useState(responseError || {});
  const [password, setPassword] = useState('');
  const [emailWithDomain, setEmailWithDomain] = useState(email);
  const [showEmail, setShowEmail] = useState(true);

  useEffect(() => {
    if (!isEmpty(emailWithDomain)) return;

    const params = new URLSearchParams(window.location.search);
    setEmailWithDomain(params.get('email') || '');
  }, []);

  const isFormValid = compact(values(error)).length === 0
    && emailWithDomain.length > 0
    && password.length > 0;

  const setters = {
    setError,
    setEmailWithDomain,
  };

  const handleUserChange = (v, e) => {
    helpers.updateInputValue(e, setters);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    authOptions(emailWithDomain).then(({ data: { method, connection, require_confirmation } }) => {
      if (method === 'auth0') {
        submitAuth0Form(connection);
      } else if (require_confirmation) {
        onConfirmationRequred(emailWithDomain);
      } else {
        setShowEmail(false);
      }
    }).catch((err) => {
      console.error('authOptions error:', err);
      setError(err);
    }).finally(() => {
      setProcessing(false);
    });
  };

  const handleSubmit = (e) => {
    if (!isFormValid) return;
    setProcessing(true);
    e.preventDefault();

    authOptions(emailWithDomain).then(({ data: { method, connection, require_confirmation } }) => {
      if (method === 'auth0') {
        submitAuth0Form(connection);
      } else if (require_confirmation) {
        onConfirmationRequred(emailWithDomain);
      } else {
        const userData = {
          email: emailWithDomain,
          password,
        };
        onUserFormSubmit(userData);
      }
    }).catch((err) => {
      console.error('authOptions error:', err);
      setError(err);
    }).finally(() => {
      setProcessing(false);
    });
  };

  const handleForgotEmailClick = () => {
    const isEmailValid = isEmpty(error.emailWithDomain);
    onForgotClick(isEmailValid ? emailWithDomain : '');
  };

  return (
    <div className="user-auth-step">
      <h3 className="user-auth-step__title">Sign in to Apptopia</h3>
      <UserEmailForm
        emailWithDomain={emailWithDomain}
        error={error}
        processing={processing}
        handleUserChange={handleUserChange}
        handleEmailSubmit={handleEmailSubmit}
        showEmail={!showEmail}
      />
      <UserPasswordForm
        showEmail={showEmail}
        password={password}
        isFormValid={isFormValid}
        error={responseError}
        processing={processing}
        handleUserChange={handleUserChange}
        handlePasswordChange={setPassword}
        handleSubmit={handleSubmit}
      />

      <UserSocials
        links={links}
        showEmail={showEmail}
        onForgotClick={handleForgotEmailClick}
      />
      <Auth0Form formId={auth0FormId} links={links} />
    </div>
  );
};

UserAuthFormStep.propTypes = {
  links: T.object.isRequired,
  processing: T.bool.isRequired,
  setProcessing: T.func.isRequired,
  onUserFormSubmit: T.func,
  onForgotClick: T.func,
  onConfirmationRequred: T.func,
  responseError: T.object,
  email: T.string,
};

export default UserAuthFormStep;
