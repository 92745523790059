import React from 'react';
import T from 'prop-types';
import Button from '../../../../components/Buttons/Button';

const ConfirmationStep = ({
  onResendClick,
}) => (
  <div className="user-auth-message">
    <div className="user-auth-message-heading">
      Account Confirmation Required
    </div>
    <div className="user-auth-message-text">
      An email will be sent from <a href="mailto:success@apptopia.com">success@apptopia.com</a>.
      Please check your spam folders. If you need additional assistance please email <a href="mailto:success@apptopia.com">success@apptopia.com</a>.
    </div>
    <div className="user-auth-message-button">
      <Button onClick={onResendClick} className="hms-btn hms-btn--lg hms-btn--primary">
        Re-Send Confirmation Email
      </Button>
    </div>
  </div>
);

ConfirmationStep.propTypes = {
  onResendClick: T.func.isRequired,
};

export default ConfirmationStep;
