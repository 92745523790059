import React from 'react';
import T from 'prop-types';

const Auth0Form = ({
  links,
  formId,
}) => (
  <form id={formId} className="d-none" method="post" action={links.auth0}>
    <input
      type="hidden"
      name="authenticity_token"
      value={document.querySelector('[name=csrf-token]').content}
    />
    <input type="submit" value="Login with Auth0" />
  </form>
);

Auth0Form.propTypes = {
  links: T.object.isRequired,
  formId: T.string.isRequired,
};

export default Auth0Form;
