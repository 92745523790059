export const prodApptopiaUrl = 'https://apptopia.com';

export const demoPageUrl = '/demo';

export const alphaIntelligenceUrl = '/alpha-intelligence';
export const bloombergAppUrl = '/bloomberg-app';
export const publicFinanceUrl = '/public-finance';

export const financeReportPageUrl = '/free-company-intelligence-report';
export const marketReportPageUrl = '/free-competitive-intelligence-report';
export const alchemyPricingPath = `${prodApptopiaUrl}/pricing`;
